import React, { useState, useEffect, useRef, Component } from "react";
import { withNamespaces } from "react-i18next";
import StatusIndicator from "../../components/StatusIndicator/StatusIndicator";
import UserListingBadge from "../../components/UserListingBadge/UserListingBadge";
import { DataTable } from "../../components/DataTable";
import { Pagination } from "../../components/Pagination";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import "./UserListing.scss";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getUsersListing } from "../../store/actions";
import { SortOrder } from "../../constants";
import { getUserStatus } from "../../common/common";
import { Container, Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { useHistory } from "react-router-dom";

const USER_COLUMNS = {
    NAME: "Name",
    STATUS: "Status",
    CURRENT_CONNECTION: "Current Connection",
    CURRENT_MATCHES: "Current Matches"
};

const SORTABLE_COLUMNS = {
    [USER_COLUMNS.NAME]: "FullName",
    [USER_COLUMNS.STATUS]: "Status"
};

const UserListing = (props) => {
    const history = useHistory();
    const { fetching, data, count } = props;
    const [page, setPage] = useState(1);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);

    const usersListAbortController = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        usersListAbortController.current = new AbortController();
        dispatch(
            getUsersListing(
                {
                    Page: page,
                    ...(currentSortColumn &&
                        currentSortOrder && {
                        Column: currentSortColumn,
                        Direction: currentSortOrder
                    })
                },
                usersListAbortController.current.signal
            )
        );
        return () => {
            if (usersListAbortController.current) {
                usersListAbortController.current.abort();
            }
        };
    }, [page, currentSortOrder, currentSortColumn, dispatch]);

    const handleSortClick = (data) => {
        let sortOrder;
        if (!currentSortOrder || currentSortOrder === SortOrder.DESC) {
            sortOrder = SortOrder.ASC;
        } else {
            sortOrder = SortOrder.DESC;
        }

        setCurrentSortColumn(SORTABLE_COLUMNS[data.title]);
        setCurrentSortOrder(sortOrder);
    };

    const handlePageClick = (pageNo) => {
        setPage(pageNo);
    };

    const renderUser = (user) => {
        return <UserListingBadge src={user.MediaPath} name={user.FullName}  onClick={() => {
            history.push(getRoute(APP_ROUTES.USER_DETAIL, { id: user.UserId }));
        }}/>;
    };

    const renderStatus = (status) => {
        const userStatus = getUserStatus(status);
        return <StatusIndicator color={userStatus.color} badgeTitle={userStatus.text}/>;
    };

    const renderConnections = (connections) => {
        let connectionsRow = [];
        connections.forEach((connection) => {
            connectionsRow.push(
                <UserListingBadge
                    src={connection.MediaPath}
                    name={connection.FullName}
                    className="mr-4"
                    onClick={() => {
                        history.push(getRoute(APP_ROUTES.USER_DETAIL, { id: connection.UserId }));
                    }}
                />
            );
        });

        return connectionsRow;
    };

    const handleRowClick = (e, data) => {
        if (data && data.UserId) {
            history.push(getRoute(APP_ROUTES.USER_DETAIL, { id: data.UserId }));
        }
    };
    
    const renderMatches = (matches) => {
        let matchRow = [];
        matches.forEach((match) => {
            matchRow.push(
                <UserListingBadge src={match.MediaPath} name={match.FullName} className="mr-4"
                  onClick={() => {
                    history.push(getRoute(APP_ROUTES.USER_DETAIL, { id: match.UserId }));
                }}/>
            );
        });

        return matchRow;
    };

    return (
        <>
            <LayoutWrapper breadCrumb={false}>
                <div className="hc-listing-table table-responsive">
                    <Row className="m-0">
                        <Col xs="12" className="p-0">
                            <Card>
                                <CardBody className="p-0">
                                    <DataTable
                                        data={data}
                                        loading={fetching}
                                        currentSortOrder={currentSortOrder}
                                        currentSortColumn={currentSortColumn}
                                        onSortClick={handleSortClick}
                                        config={[
                                            {
                                                title: USER_COLUMNS.NAME,
                                                isSortable: true,
                                                render: (data) => renderUser(data.User),
                                                sortColumn: SORTABLE_COLUMNS[USER_COLUMNS.NAME]
                                            },
                                            {
                                                title: USER_COLUMNS.STATUS,
                                                isSortable: true,
                                                render: (data) => renderStatus(data.Status),
                                                sortColumn: SORTABLE_COLUMNS[USER_COLUMNS.STATUS]
                                            },
                                            {
                                                title: USER_COLUMNS.CURRENT_CONNECTION,
                                                render: (data) =>
                                                    renderConnections(data.Connections)
                                            },
                                            {
                                                title: USER_COLUMNS.CURRENT_MATCHES,
                                                render: (data) => renderMatches(data.Matches)
                                            }
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={count}
                                        onPageClick={handlePageClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </LayoutWrapper>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        fetching: state.user.fetching,
        count: state.user.count,
        data: state.user.list
    };
};

export default withNamespaces()(connect(mapStateToProps, null)(UserListing));
