export default {
    UNVERIFIED: 0,
    VERIFIED: 1,
    COMPLETING_PROFILE: 3,
    MATCH_READY: 4,
    SELECTING_PREFERENCES: 5,
    SUSPENDED: 6,
    REMOVED: 7,
    ID_VERIFICATION_PENDING: 8,
    DELETED: 9
};
