import React from 'react'
import { Col, Row } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CardLeftImg, CardContainer } from './TooltipDesignMain.styles';
import BlockButton from "../../../Button/Button";
import Accordion from "../../../Accordion/Accordion";
import DataTile from "../../../Tiles/DataTile";
import { APP_ROUTES } from '../../../../helpers/routeHelpers';

import profilePicture from '../../../../assets/images/realuser_lg.png';

const TooltipCustomCard = (props) => {
    const history = useHistory();
    return (
        <CardContainer className="">
            <Row>
                <Col xs={12} lg={6} className="mb-4">
                    <Row>
                        <Col xs={12} lg={4}>
                            <CardLeftImg
                                className="rounded-circle"
                                src={profilePicture}
                                alt="userIcon"
                                imgWidth="6.64rem"
                            />
                        </Col>
                        <Col xs={12} lg={8}>
                            <h1>Imram Sheikh</h1>
                            <p className="body-text-3">{props.t('Scottish Pakistani')}</p>
                            <Row>
                                <Col xs={12} lg={4}>
                                    <p className="body-text-3">23</p>
                                </Col>
                                <Col xs={12} lg={8}>
                                    <BlockButton
                                        className="button-type-2 btn btn-sm body-text-8 mb-3 mb-sm-3 mb-md-3  mb-lg-0 mb-xl-0"
                                        onClick={() =>
                                            history.push(APP_ROUTES.ACTIVE_CHATS)
                                        }
                                    >
                                        {props.t('Message')}
                                    </BlockButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <h1 className="color-secondary-blue">{props.t('About Imran')}</h1>
                    <p className="body-text-3">{props.t('lorem ipsum long')}</p>
                    <Row>
                        <Col xs={12} lg={6}>
                            <BlockButton
                                className="button-type-1 btn btn-md mr-4 mb-3 mb-sm-3 mb-md-3  mb-lg-0 mb-xl-0"
                                onClick={() =>
                                    history.push(APP_ROUTES.SEE_CONNECTIONS)
                                }
                            >
                                {props.t("Find a match")}
                            </BlockButton>
                        </Col>
                        <Col xs={12} lg={6}>
                            <BlockButton
                                className="button-type-1 btn btn-md mb-3 mb-sm-3 mb-md-3  mb-lg-0 mb-xl-0"
                                onClick={() =>
                                    history.push(APP_ROUTES.COMPARE_PROFILE)
                                }
                            >
                                {props.t("Find Manually")}
                            </BlockButton>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={6}>
                    <Accordion
                        containerClass="mb-3"
                        isOpen={false}
                        headTitle="Personal"
                    >
                        <Row>
                            <Col xs={12} sm={12}>
                                <DataTile
                                    title={props.t("Height")}
                                    data={'Data'}
                                />
                            </Col>
                        </Row>
                    </Accordion>
                    <Accordion
                        containerClass="mb-3"
                        isOpen={false}
                        headTitle="Professional"
                    >
                        <Row>
                            <Col xs={12} sm={12}>
                                <DataTile
                                    title={props.t("Height")}
                                    data={'Data'}
                                />
                            </Col>
                        </Row>
                    </Accordion>
                    <Accordion
                        containerClass="mb-3"
                        isOpen={false}
                        headTitle="Religious"
                    >
                        <Row>
                            <Col xs={12} sm={12}>
                                <DataTile
                                    title={props.t("Height")}
                                    data={'Data'}
                                />
                            </Col>
                        </Row>
                    </Accordion>
                    <Accordion
                        containerClass="mb-3"
                        isOpen={false}
                        headTitle="Personality Traits"
                    >
                        <Row>
                            <Col xs={12} sm={12}>
                                <DataTile
                                    title={props.t("Height")}
                                    data={'Data'}
                                />
                            </Col>
                        </Row>
                    </Accordion>
                    <Accordion
                        containerClass="mb-3"
                        isOpen={false}
                        headTitle="Preferences"
                    >
                        <Row>
                            <Col xs={12} sm={12}>
                                <DataTile
                                    title={props.t("Height")}
                                    data={'Data'}
                                />
                            </Col>
                        </Row>
                    </Accordion>
                </Col>
            </Row>
        </CardContainer>
    )
}

export default withNamespaces()(TooltipCustomCard);