import React from "react";
import { withNamespaces } from "react-i18next";
import { store } from "../../store";

import "./Chats.scss";

const ChatListItemAvatar = (props) => {
    const { multiple = false, classes, data, isOverlay } = props;
    return (
        <div
            className={`chat-nav-list-item-left  d-flex align-items-center justify-content-center position-relative ${
                data.length > 0 ? "p-r-xxl multiple" : "p-h-lg"
            } ${classes ? classes : ""}`}
        >
            {!!data.length &&
                data.map((user, i) => {
                    if (user.UserId !== store.getState().auth.user.Id)
                        return user.MediaPath ? (
                            <img
                                key={i}
                                className={`rounded-circle ${
                                    !isOverlay && i > 0 ? "averlay-img position-absolute" : ""
                                }`}
                                src={user.MediaPath}
                                alt={user.Name}
                                width="50"
                                height="50"
                            />
                        ) : (
                            <span
                                key={i}
                                className={`avatar-title rounded-circle ${
                                    props.imgClass ? props.imgClass : ""
                                }`}
                            >
                                {user?.Name?.charAt(0)?.toUpperCase()}
                            </span>
                        );
                })}
        </div>
    );
};

export default withNamespaces()(ChatListItemAvatar);
