import { APP_ROUTES } from "./helpers/routeHelpers";
const users = require("./assets/images/icons-svg/users.svg");
const users_active = require("./assets/images/icons-svg/users-active.svg");
const message_circle = require("./assets/images/icons-svg/message-circle.svg");
const message_circle_active = require("./assets/images/icons-svg/message-circle-active.svg");
const alert_triangle = require("./assets/images/icons-svg/alert-triangle.svg");
const alert_triangle_active = require("./assets/images/icons-svg/alert-triangle-active.svg");
const chat_arrow = require("./assets/images/icons-svg/chat-arrow.svg");
const chat_arrow_active = require("./assets/images/icons-svg/chat-arrow-active.svg");
const settings = require("./assets/images/icons-svg/settings.svg");
const settings_active = require("./assets/images/icons-svg/settings-active.svg");
const log_in = require("./assets/images/icons-svg/log-in.svg");
const log_in_active = require("./assets/images/icons-svg/log-in-active.svg");

const sidebarConfig = [
    {
        url: APP_ROUTES.USER_LISTING,
        childrenUrl: [APP_ROUTES.USER_DETAIL, APP_ROUTES.COMPARE_PROFILE, APP_ROUTES.MATCH_LISTING],
        svgIcon: users,
        svgIconActive: users_active,
        class: "sidebar-config-user"
    },
    {
        url: APP_ROUTES.ID_VERIFICATION,
        svgIcon: users,
        svgIconActive: users_active,
        class: "sidebar-config-chat"
    },
    {
        url: APP_ROUTES.CHAT_LISTING,
        svgIcon: message_circle,
        svgIconActive: message_circle_active,
        class: "sidebar-config-chat"
    },
    {
        url: APP_ROUTES.REPORT_LISTING,
        childrenUrl: [APP_ROUTES.REPORT_DETAIL],
        svgIcon: alert_triangle,
        svgIconActive: alert_triangle_active,
        class: "sidebar-config-report"
    },
    {
        url: APP_ROUTES.PREVIOUS_CHAT,
        svgIcon: chat_arrow,
        svgIconActive: chat_arrow_active,
        class: "margin-bottom-50vh sidebar-config-chat-arrow"
    },
    {
        url: APP_ROUTES.SETTINGS,
        childrenUrl: [
            APP_ROUTES.ACCOUNT_SETTINGS,
            APP_ROUTES.NOTIFICATION_SETTINGS,
            APP_ROUTES.HELPFAQS,
            APP_ROUTES.ABOUT_US
        ],
        svgIcon: settings,
        svgIconActive: settings_active,
        class: "sidebar-config-settings"
    },
    {
        url: "logout",
        svgIcon: log_in,
        svgIconActive: log_in_active,
        class: "sidebar-config-logout"
    }
];

export default sidebarConfig;
